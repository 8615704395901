import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { chatsService } from "../../../../services";
import { TemplateService } from "./service";
import { IChatTemplate } from "./@types";

const useResendTemplate = () => {
  const { selectedChat } = useAppSelector((state) => state.chats);
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [chatTemplate, setChatTemplate] = useState<IChatTemplate | boolean>(
    false
  );

  const [contact] = selectedChat?.users ?? [];

  async function checkActiveChatAndNavigate(): Promise<
    { id: string; payload: any } | false
  > {
    if (!user) {
      throw new Error("User is not defined");
    }

    const service = new TemplateService(
      {
        contact,
        user,
        chatTemplate: chatTemplate as IChatTemplate,
      },
      dispatch
    );

    const payload = await service.hasActiveChat();

    return payload ? { id: payload.id, payload: payload.payload } : false;
  }

  async function sendTemplate() {
    if (!user) {
      throw new Error("User is not defined");
    }

    const service = new TemplateService(
      {
        contact,
        user,
        chatTemplate: chatTemplate as IChatTemplate,
      },
      dispatch
    );

    let newMessage = null;
    let wppMessage = null;
    let internalMessage = null;
    try {
      const newChat: any = await service.createNewChat(selectedChat?.type!);

      if (newChat) {
        newMessage = await service.createMessage(newChat);
      }

      if (newMessage) {
        wppMessage = await service.createWppMessage(newMessage, newChat);
      }

      if (wppMessage) {
        internalMessage = await service.sendInternalMsg(newChat);
      }

      if (internalMessage) {
        navigate(`/chat/${newChat._id}`);
      }
    } catch (error) {}
  }

  useEffect(() => {
    async function handleGetChatTemplate() {
      if (selectedChat?._id) {
        const chatTemplate = await chatsService.getChatTemplate(
          selectedChat._id
        );

        setChatTemplate(chatTemplate);
      }
    }

    handleGetChatTemplate();
  }, [selectedChat?.status]);

  return { chatTemplate, sendTemplate, checkActiveChatAndNavigate };
};

export default useResendTemplate;
