import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DropdownSearchInputProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownSearchInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import UserCard from "../../../shared/components/UserCard.component";
import {
  IUser,
  RoleUser,
  selectAgents,
  selectContacts,
  selectTotalAgents,
  selectTotalContacts,
  UserType,
} from "../../../slices/users.slice";
import {
  addToRecents,
  ChatStatus,
  ChatType,
  closeChat,
  createChat,
  IChat,
  openChat,
  removeFromRecents,
  selectChat,
} from "../../../slices/chats.slice";
import SearchBox from "../../../shared/components/SearchBox.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import useOnScreen from "../../../hooks/useOnScreen";
import { getSettings } from "../../../slices/settings.slice";
import removeDuplicates from "../../../shared/utils/removeDuplicates";
import RelationModal from "../../../shared/components/Modals/RelationModal.component";
import {
  replyTime,
  resetWaitingMessages,
} from "../../../slices/messages.slice";
import ConfirmationModal from "../../../shared/components/Modals/ConfirmationModal.component";
import Dropdown from "../../../shared/components/Dropdown.component";
import { ddOptions } from "../../../shared/models/interfaces/dropdownOptions.interface";
import {
  customersService,
  teamsService,
  usersService,
} from "../../../services";
import CreateModal from "../../../shared/components/Modals/CreateModal.component";
import customToast from "../../../shared/utils/customToast";
import { IToastType } from "../../../shared/models/types/Toast.type";

const ChatUsers = ({
  widgetType,
  setShow,
  toggleList,
  showWidgetAlert,
  setShowWidgetAlert,
  showNoTeamsAlert,
  setShowNoTeamsAlert,
  showNewGroupChat,
  setShowNewGroupChat,
}: {
  widgetType?: UserType;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  toggleList: string;
  showWidgetAlert?: boolean;
  setShowWidgetAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  showNoTeamsAlert?: boolean;
  setShowNoTeamsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  showNewGroupChat: boolean;
  setShowNewGroupChat: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [skipUsers, setSkipUsers] = useState<number>(0);
  const [skipContacts, setSkipContacts] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  const [hasMoreContacts, setHasMoreContacts] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertUser, setAlertUser] = useState<{
    user: { _id: string; name: string };
    team: string;
  }>({
    user: { _id: "", name: "" },
    team: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { users, contacts, totalUsers, totalContacts } = useAppSelector(
    (state) => state.users
  );
  const { mineChats, selectedChat } = useAppSelector((state) => state.chats);
  const { user } = useAppSelector((state) => state.auth);
  const { externalAppWhatsApp, externalAppBotmaker } = useAppSelector(
    (state) => state.externalapps
  );

  const [listRef] = useOnScreen({
    hasMore: toggleList === "users" ? hasMoreUsers : hasMoreContacts,
    isLoading,
    setSkip: toggleList === "users" ? setSkipUsers : setSkipContacts,
  });
  const [renderDataUsers, setRenderDataUsers] = useState<IUser[]>([]);
  const [renderDataContacts, setRenderDataContacts] = useState<IUser[]>([]);
  const [contactId, setContactId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);
  const [focusId, setFocusId] = useState<string>("");
  const [selectedDdteams, setSelectedDdteams] = useState<string>();
  const [ddteams, setDdteams] = useState<ddOptions[]>([]);
  const [selectedDdcustomers, setSelectedDdcustomers] = useState<string>();
  const [ddcustomers, setDdcustomers] = useState<ddOptions[]>([]);

  const contactBusyMessage = `${t("modal.busy-contact.message.firstline")} <b>${
    alertUser.user.name
  }</b> ${t("modal.busy-contact.message.secondline")} <b>${
    alertUser.team
  }</b>.\n\n${t("modal.busy-contact.message.lastline")}`;

  const conditions = {
    users: () => toggleList === "users" || searchKeyword.length > 0,
    contacts: () =>
      toggleList === "contacts" ||
      toggleList !== "users" ||
      searchKeyword.length > 0,
  };

  useEffect(() => {
    (async () => {
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setSkipContacts(0);
    setSkipUsers(0);
    dispatch(selectTotalContacts(0));
    dispatch(selectTotalAgents(0));
    dispatch(selectContacts([]));
    dispatch(selectAgents([]));
  }, [toggleList]);

  useEffect(() => {
    if (toggleList === "users") {
      if (skipUsers + 10 >= totalUsers) {
        setHasMoreUsers(false);
      } else {
        setHasMoreUsers(true);
      }
      setRenderDataUsers(
        users.filter((_user: IUser) => _user._id !== user?._id)
      );
    }
  }, [users]);

  useEffect(() => {
    if (toggleList === "contacts") {
      if (skipContacts + 10 >= totalContacts) {
        setHasMoreContacts(false);
      } else {
        setHasMoreContacts(true);
      }
      setRenderDataContacts(
        contacts.filter(
          (_user: IUser) =>
            _user._id !== user?._id && _user.phone && _user.phone.length > 0
        )
      );
    }
  }, [contacts]);

  useEffect(() => {
    (async () => {
      if (conditions.users() && toggleList === "users") {
        const _filters: any = {
          name: typeof searchKeyword !== "undefined" ? searchKeyword : "",
        };
        if (
          typeof selectedDdteams !== "undefined" &&
          selectedDdteams.length > 0
        ) {
          _filters.teams = selectedDdteams;
        }

        const payload = await usersService.searchAgents({
          skip: skipUsers,
          limit: 10,
          filter: _filters,
          deleted: false,
        });
        const _results = payload.results.filter(
          (_user: IUser) => _user._id !== user?._id
        );
        setIsLoading(false);
        if (skipUsers + 10 >= payload.count - 1) {
          setHasMoreUsers(false);
        } else {
          setHasMoreUsers(true);
        }
        if (skipUsers > 0) {
          setRenderDataUsers(renderDataUsers.concat(_results));
          dispatch(selectAgents(renderDataUsers.concat(_results)));
        } else {
          setRenderDataUsers(_results);
          dispatch(selectAgents(_results));
        }
        dispatch(selectTotalAgents(payload.count));
      }
    })();
  }, [skipUsers, searchKeyword, toggleList, selectedDdteams]);

  useEffect(() => {
    (async () => {
      if (conditions.contacts() && toggleList !== "users") {
        const _filters: any = {
          content: typeof searchKeyword !== "undefined" ? searchKeyword : "",
        };

        if (
          typeof selectedDdcustomers !== "undefined" &&
          selectedDdcustomers.length > 0
        ) {
          _filters.customers = selectedDdcustomers;
        }

        const payload = await usersService.searchContacts({
          skip: skipContacts,
          limit: 10,
          filter: _filters,
          deleted: false,
          spam: false,
        });
        const _results = payload.results.filter(
          (_user: IUser) =>
            _user._id !== user?._id && _user.phone && _user.phone.length > 0
        );
        setIsLoading(false);
        if (skipContacts + 10 >= payload.count) {
          setHasMoreContacts(false);
        } else {
          setHasMoreContacts(true);
        }
        if (skipContacts > 0) {
          setRenderDataContacts(renderDataContacts.concat(_results));
          dispatch(selectContacts(renderDataContacts.concat(_results)));
        } else {
          setRenderDataContacts(_results);
          dispatch(selectContacts(_results));
        }
        dispatch(selectTotalContacts(payload.count));
      }
    })();
  }, [skipContacts, searchKeyword, toggleList, selectedDdcustomers]);

  const handleClick = async ({ uid, type }: { uid: string; type?: string }) => {
    try {
      let chat = null;
      if (!type) {
        if (!chat && mineChats.length > 0) {
          chat = mineChats.filter((_chat: IChat) => {
            const _found = _chat?.users?.filter(
              (_user) =>
                _user._id === uid && !_user.roles?.includes(RoleUser.CONTACT)
            );
            if (_found && _found.length > 0) {
              return _chat;
            }
            return null;
          });
        }
        if (chat && chat.length === 1) {
          dispatch(removeFromRecents(chat[0]));
          navigate(`/chat/${chat[0]._id}`);
          setShow(false);
        }
      }

      const getType = () => {
        if (toggleList === "users" || showAlert) {
          return ChatType.INTERNAL;
        }
        return ChatType.WHATSAPP;
      };

      let _users: Array<string> = [];
      _users.push(uid);
      if (toggleList !== "users" && !showAlert) {
        _users.push(user?._id || "");
      }
      _users = removeDuplicates(_users);
      const { payload } = await dispatch(
        createChat({
          users: _users,
          status: ChatStatus.ACTIVE,
          type: getType(),
          externalapp:
            getType() === ChatType.WHATSAPP
              ? user?.profile?.externalapp || undefined
              : undefined,
        })
      );
      if (showAlert) setShowAlert(false);
      const _chat = payload as IChat;
      navigate(`/chat/${_chat._id}`);
      setShow(false);
    } catch (error) {
      // console.log(error);
    }
  };

  interface noUsersObject {
    [key: string]: string;
  }

  const noUsers: noUsersObject = {
    users: t("chat-users.users"),
    contacts: t("chat-users.contacts"),
    usersresearch: t("chat-users.usersresearch"),
    contactsresearch: t("chat-users.contactsresearch"),
  };

  const emptyList = (content: noUsersObject[string]) => {
    return (
      <div className="flex relative mt-[20px]">
        <h2 className="my-0 mx-auto mt-[50px]">{content}</h2>
      </div>
    );
  };

  const getActionFromReply = async (_userId: string, payload: any) => {
    const returnChat =
      payload && payload?._id && typeof payload?.status !== "undefined";
    const returnBusy =
      payload &&
      payload?.user &&
      payload?.team &&
      typeof payload?.status === "undefined";
    const returnEmpty = payload && typeof payload?.id === "undefined";

    if (returnChat) {
      // setShowModal(true);
      if (
        [
          ChatStatus.ACTIVE,
          ChatStatus.WAIT,
          ChatStatus.WAITCAMPAIGN,
          ChatStatus.WAITREPLY,
        ].includes(payload.status)
      ) {
        const _users = payload?.users || null;
        if (_users) {
          dispatch(removeFromRecents(payload));
          if (selectedChat?._id && widgetType !== UserType.CONTACT) {
            dispatch(addToRecents(selectedChat));
            dispatch(closeChat());
          }
          navigate(`/chat/${payload._id}`);
          setShow(false);
          const _action = await dispatch(selectChat({ _id: payload._id }));
          if (_action.payload) {
            dispatch(resetWaitingMessages());
            dispatch(openChat(_action.payload));
          }
        }
      } else {
        handleClick({ uid: _userId, type: ChatType.WHATSAPP });
      }
      return undefined;
    }
    if (returnBusy) {
      setContactId(_userId);
      setAlertUser({
        team: payload?.team?.name,
        user: {
          _id: payload?.user?._id,
          name: payload?.user?.name,
        },
      });
      setShowAlert(true);
      return true;
    }
    if (returnEmpty) {
      if (!showModal) {
        setContactId(_userId);
        setShowModal(true);
      }
      return false;
    }
    return false;
  };

  const validateOlderWhatsAppChats = async ({
    _user,
    _externalapp,
  }: {
    _user: IUser | undefined;
    _externalapp?: string;
  }) => {
    if (_user && _user._id) {
      const { payload } = await dispatch(
        replyTime({
          contactId: _user._id,
          externalapp: _externalapp || user?.profile?.externalapp,
        })
      );
      return getActionFromReply(_user._id, payload);
    }
    return false;
  };

  const openModal = (_user: IUser) => {
    if (_user && _user?._id) {
      setContactId(_user._id);
      setShowModal(true);
    }
  };

  const viaWhatsApp = async (_user: IUser) => {
    // if (externalAppBotmaker.length > 0) {
    //   return openModal(_user);
    // }
    if (externalAppWhatsApp.length < 1 && externalAppBotmaker.length < 1) {
      customToast({
        type: IToastType.WARNING,
        message: t("toast.warning.profile"),
      });
    }
    if (externalAppWhatsApp.length === 1 || externalAppBotmaker.length === 1) {
      const _externalApp =
        typeof externalAppWhatsApp[0]?._id !== "undefined"
          ? externalAppWhatsApp[0]
          : externalAppBotmaker[0];
      if (
        user?.profile?.externalapp ||
        typeof _externalApp._id !== "undefined"
      ) {
        await validateOlderWhatsAppChats({
          _user,
          _externalapp: user?.profile?.externalapp || _externalApp._id,
        });
      }
      return openModal(_user);
    }
    if (user?.profile?.externalapp) {
      await validateOlderWhatsAppChats({
        _user,
        _externalapp: user?.profile?.externalapp,
      });
    }
    return openModal(_user);
  };

  const listBehavior = ({ list }: { list: IUser[] }) => {
    return (
      <ul
        id={widgetType === UserType.AGENT ? "sidemenu" : "chatusers"}
        className="overflow-x-hidden overflow-y-scroll flex-1 relative pl-4 pr-2"
      >
        {list.map((_user, index) => (
          <li
            ref={index === list.length - 1 ? listRef : null}
            aria-hidden
            key={_user?._id}
          >
            <UserCard
              item={_user}
              toggleList={toggleList}
              viaAgitalks={() => {
                if (_user && _user?._id) handleClick({ uid: _user._id });
              }}
              viaWhatsApp={() => {
                viaWhatsApp(_user);
              }}
            />
            <div className="w-full border-t border-gray-ddd my-1" />
          </li>
        ))}
        {isLoading ? (
          <li
            key="loading"
            className="h-[100px] p-2 pl-0 hover:bg-gray-200 hover:rounded-xl border-l-[6px] border-l-transparent cursor-pointer flex relative items-center justify-between"
          >
            <LoadingDots className="mx-auto p-4 flex justify-center items-center my-auto" />
            {/* <div className="w-full border-t border-gray-ddd my-1" /> */}
          </li>
        ) : null}
      </ul>
    );
  };

  const getListLengthCondition = () =>
    toggleList === "users" ? users.length > 0 : contacts.length > 0;

  const getLoadingCondition = () =>
    toggleList === "users"
      ? isLoading && users.length === 0
      : isLoading && contacts.length === 0;

  const getList = () => {
    if (getListLengthCondition()) {
      return listBehavior({
        list: toggleList === "users" ? users : contacts,
      });
    }
    return emptyList(
      searchKeyword.length > 0
        ? noUsers[`${toggleList}research`]
        : noUsers[toggleList]
    );
  };
  const ddFiltersPars: any = {
    teams: {
      id: "dropdown-user-teams",
      label: t("form.label.teams"),
      defaultValue: selectedDdteams,
      placeholder: t("form.placeholder.teams"),
      options: ddteams,
      searchMethod: (_filter: any) =>
        teamsService.search({
          skip: 0,
          limit: 10,
          filter: typeof _filter !== "undefined" ? _filter : {},
          deleted: false,
          withChatusers: true,
        }),
      onChangeCallBack: (param?: string) => setSelectedDdteams(param),
      searchCallBack: (param: ddOptions[]) => setDdteams(param),
    },
    customers: {
      id: "dropdown-contact-customers",
      label: t("form.label.customers"),
      defaultValue: selectedDdcustomers,
      placeholder: t("form.placeholder.customers"),
      options: ddcustomers,
      searchMethod: (_filter: any) =>
        customersService.search({
          skip: 0,
          limit: 10,
          filter: typeof _filter !== "undefined" ? _filter : {},
          deleted: false,
        }),
      onChangeCallBack: (param?: string) => setSelectedDdcustomers(param),
      searchCallBack: (param: ddOptions[]) => setDdcustomers(param),
    },
  };

  const ddInstance = toggleList === "users" ? "teams" : "customers";

  return (
    <>
      <Dropdown
        className="py-[20px] pb-[10px] pl-[10px] pr-[20px]"
        id={ddFiltersPars[ddInstance].id}
        loading={isLoadingDropdown && focusId === ddFiltersPars[ddInstance].id}
        required
        multiple
        label={ddFiltersPars[ddInstance].label}
        fluid
        search
        selection
        defaultValue={ddFiltersPars[ddInstance].defaultValue}
        placeholder={ddFiltersPars[ddInstance].placeholder}
        options={ddFiltersPars[ddInstance].options}
        onFocus={() => {
          setFocusId(ddFiltersPars[ddInstance].id);
        }}
        onBlur={() => {
          setFocusId("");
        }}
        onSearchChange={async (e: DropdownSearchInputProps) => {
          setIsLoadingDropdown(true);
          const payload = await ddFiltersPars[ddInstance].searchMethod(
            e.target.value
          );
          setIsLoadingDropdown(false);
          const _optionsInstance: ddOptions[] =
            ddFiltersPars[ddInstance].options;
          payload.results.map((_opt: any) => {
            if (
              _optionsInstance.filter((_option) => _option.key === _opt._id)[0]
            ) {
              return false;
            }
            _optionsInstance.push({
              key: _opt._id,
              text: _opt.name,
              value: _opt._id,
            });
            return true;
          });
          ddFiltersPars[ddInstance].searchCallBack(_optionsInstance);
        }}
        onOpen={async () => {
          setIsLoadingDropdown(true);
          ddFiltersPars[ddInstance].searchCallBack([]);
          const payload = await ddFiltersPars[ddInstance].searchMethod();
          setIsLoadingDropdown(false);
          const _options: ddOptions[] = [];
          payload.results.map((_result: any) =>
            _options.push({
              key: _result._id,
              text: _result.name,
              value: _result._id,
            })
          );
          ddFiltersPars[ddInstance].searchCallBack(_options);
        }}
        onChange={(e, { value }) => {
          const _value = value as string;
          ddFiltersPars[ddInstance].onChangeCallBack(_value);
        }}
      />
      <SearchBox
        extraClass="bg-white my-[10px] ml-[10px] mr-[20px] rounded-[4px] h-[38.5px]"
        extraClassInner="rounded-[4px]"
        filters
        label={
          toggleList === "users"
            ? t("form.label.users") || ""
            : t("form.label.contacts") || ""
        }
        _escFunction={() => {
          setSearchKeyword("");
          if (toggleList === "users") {
            setSkipUsers(0);
          } else {
            setSkipContacts(0);
          }
        }}
        keyword={searchKeyword}
        listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchKeyword(e.target.value);
          if (toggleList === "users") {
            setSkipUsers(0);
          } else {
            setSkipContacts(0);
          }
        }}
      />

      {getLoadingCondition() ? (
        <LoadingDots className="flex flex-1 justify-center items-center" />
      ) : (
        <div className="overflow-x-hidden overflow-y-auto flex-1 relative">
          {getList()}
        </div>
      )}
      {/* MODALS */}
      {showModal ? (
        <RelationModal
          widgetType={widgetType}
          title={t("modal.template.title")}
          icon="lab la-whatsapp"
          setShowModal={setShowModal}
          setShow={setShow}
          contactChat={contacts.filter((_c) => _c._id === contactId)[0]}
          validateOlderWhatsAppChats={validateOlderWhatsAppChats}
        />
      ) : null}
      {showAlert ? (
        <ConfirmationModal
          widgetType={widgetType}
          disabled={false}
          title={t("modal.busy-contact.title")}
          submitLabel="Conversar com o agente"
          submitIcon="las la-comments"
          content={contactBusyMessage}
          setShowModal={setShowAlert}
          action={() => handleClick({ uid: alertUser.user._id })}
          cancelAction={() => {
            setShowAlert(false);
            setShowModal(false);
          }}
        />
      ) : null}
      {showWidgetAlert && setShowWidgetAlert ? (
        <ConfirmationModal
          widgetType={widgetType}
          disabled={false}
          title="Nenhuma integração encontrada"
          content="Solicite ao administrador da plataforma que configure a integração desejada."
          setShowModal={setShowWidgetAlert}
          action={() => setShowWidgetAlert(false)}
          submitIcon="las la-check"
          submitLabel="Entendi"
        />
      ) : null}
      {showNoTeamsAlert && setShowNoTeamsAlert ? (
        <ConfirmationModal
          widgetType={widgetType}
          disabled={false}
          title="Nenhuma equipe encontrada"
          content="Você não faz parte de nenhuma equipe. Entre em contato com o administrador da plataforma."
          setShowModal={setShowNoTeamsAlert}
          action={() => setShowNoTeamsAlert(false)}
          submitIcon="las la-check"
          submitLabel="Entendi"
        />
      ) : null}
      {showNewGroupChat ? (
        <CreateModal
          title="Novo grupo"
          icon="las la-users"
          setShowModal={setShowNewGroupChat}
          setShowUsersList={setShow}
          modal
        />
      ) : null}
    </>
  );
};

ChatUsers.defaultProps = {
  widgetType: UserType.NONE,
  showWidgetAlert: undefined,
  showNoTeamsAlert: undefined,
  setShowWidgetAlert: undefined,
  setShowNoTeamsAlert: undefined,
};

export default ChatUsers;
