import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import {
  changeStatus,
  logout,
  refreshLogout,
} from "../../../slices/auth.slice";
import UserProfileStatus from "../UserProfileStatus.component";
import NotificationBadge from "../../../features/chats/components/NotificationBadge.component";
import {
  ChatStatus,
  closeChat,
  selectHistoyContact,
} from "../../../slices/chats.slice";
import { UserStatus } from "../../../slices/users.slice";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import CompanyProfile from "../CompanyProfile.component";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";

interface ISideBar {
  navbar: boolean;
  toggleSidebar: boolean;
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUsersList: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu = ({
  navbar,
  toggleSidebar,
  setToggleSidebar,
  setToggleList,
  setShowHistory,
  setShowFilter,
  setShowUsersList,
}: ISideBar) => {
  const { isAdmin } = useUserIsAdmin();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );

  const [badge, setBadge] = useState<number>(0);

  useEffect(() => {
    const backlogNotifications = backlogChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.SUSPEND, ChatStatus.FINISH].includes(
          chat.status
        )
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    const mineChatsNotifications = mineChats.reduce((accumulator, chat) => {
      if (
        chat?.notifications &&
        chat?.status &&
        ![ChatStatus.CANCELED, ChatStatus.FINISH].includes(chat.status)
      ) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    setBadge(backlogNotifications + mineChatsNotifications);
  }, [backlogChats, mineChats]);

  const isActiveStyle =
    "capitalize flex item-center px-5 gap-3 font-extrabold border-r-2 rounded-l-lg border-black hover:bg-agitalks-alpha30 hover:text-black";
  const isNotActiveStyle =
    "capitalize flex item-center px-5 gap-3 text-gray-bench hover:bg-agitalks-alpha30 hover:text-black rounded-lg";

  const sidebarItems = [
    {
      title: "Conversas",
      icon: "las la-comments text-2xl",
      url: selectedChat?._id ? `/chat/${selectedChat._id}` : "/chat",
      style:
        location.pathname.includes("/chat/") || location.pathname === "/chat"
          ? isActiveStyle
          : isNotActiveStyle,
    },
    {
      title: "Grupos e usuários",
      icon: "las la-users text-2xl",
      url: "/settings/users",
      style:
        location.pathname.includes("settings/contact") ||
        location.pathname.includes("settings/customer") ||
        location.pathname.includes("settings/user") ||
        location.pathname.includes("settings/team")
          ? isActiveStyle
          : isNotActiveStyle,
    },

    {
      title: "Resultados",
      icon: "las la-chart-bar text-2xl",
      url:
        isAdmin ||
        (typeof user?.teams !== "undefined" && user?.teams?.length > 0)
          ? "/dashboards"
          : "/",
      style: location.pathname.includes("dashboard")
        ? isActiveStyle
        : isNotActiveStyle,
    },
  ];

  sidebarItems.forEach((item, index) => {
    if (
      !isAdmin &&
      (typeof user?.teams === "undefined" ||
        (typeof user?.teams !== "undefined" && user?.teams?.length <= 0)) &&
      ["Resultados"].includes(item.title)
    ) {
      delete sidebarItems[index];
    }
  });

  const sidebarFooterItems = [
    {
      title: "Configurações",
      icon: "las la-cog text-2xl",
      url: "/settings",
      style:
        location.pathname === "/settings" ? isActiveStyle : isNotActiveStyle,
    },
  ];

  const handleCloseSidebar = () => {
    setToggleSidebar(false);
  };

  const handleChats = async () => {
    if (navbar) handleCloseSidebar();
    setToggleList("mine");
    setShowHistory(false);
    setShowFilter(false);
    setShowUsersList(false);
    dispatch(selectHistoyContact(null));
    if (selectedChat?._id) {
      dispatch(closeChat());
    }
  };

  return (
    <div
      className={`${
        toggleSidebar ? "w-72" : "w-20"
      } h-full bg-white relative flex flex-col justify-between hide-scrollbar`}
    >
      <div className="flex flex-col bg-white">
        <div
          className={`mt-3 flex items-center ${
            toggleSidebar ? "justify-between" : "justify-center"
          }`}
        >
          {toggleSidebar ? (
            <CompanyProfile toggleSidebar={toggleSidebar} navbar={navbar} />
          ) : null}
          <i
            title={`${toggleSidebar ? "Esconder menu" : "Exibir menu"}`}
            role="presentation"
            className={`${
              toggleSidebar
                ? "las la-angle-left px-1"
                : `${navbar ? "hidden" : "las la-bars px-5"}`
            } cursor-pointer text-3xl flex item-center text-gray-bench hover:text-black capitalize rounded-lg`}
            onClick={() => {
              setToggleSidebar((prevState) => !prevState);
            }}
          />
        </div>

        <div className="flex flex-col justify-center gap-3 mt-4">
          {!toggleSidebar && !navbar ? (
            <CompanyProfile toggleSidebar={false} />
          ) : null}
          {sidebarItems.map((item) => {
            return (
              <NavLink
                to={item.url}
                className={item.style}
                onClick={() => {
                  if (
                    item.url === "/dashboards" &&
                    !isAdmin &&
                    (typeof user?.teams === "undefined" ||
                      user?.teams?.length === 0)
                  ) {
                    customToast({
                      message: t("toast.warning.no-team-dashs"),
                      type: IToastType.WARNING,
                    });
                  }
                  handleChats();
                }}
                key={item.title}
              >
                <div className="flex justify-center items-center">
                  <div className="w-10 h-12 flex justify-center items-center relative">
                    <i className={item.icon} title={item.title} />
                    <div className="absolute bottom-0 right-0">
                      {badge > 0 && item.icon.includes("la-comments") ? (
                        <NotificationBadge badge={badge} />
                      ) : null}
                    </div>
                  </div>
                  {toggleSidebar ? (
                    <p className="ml-2 lowercase first-letter:uppercase">
                      {item.title}
                    </p>
                  ) : null}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col bg-white gap-3">
        {sidebarFooterItems.map((item) => {
          return (
            <NavLink
              to={item.url}
              className={item.style}
              onClick={async () => {
                if (item.url === "/logout") {
                  await dispatch(
                    changeStatus({
                      status: UserStatus.OFFLINE,
                      origin: user?._id,
                    })
                  );
                  await dispatch(logout());
                  dispatch(refreshLogout());
                  localStorage.clear();
                  if (navbar) handleCloseSidebar();
                } else {
                  handleChats();
                }
              }}
              key={item.title}
            >
              <div className="flex justify-center items-center">
                <div className="w-10 h-12 flex justify-center items-center relative">
                  <i className={item.icon} title={item.title} />
                </div>

                {toggleSidebar ? (
                  <p
                    className={`ml-2 ${
                      item.url !== "/settings/profile"
                        ? "lowercase first-letter:uppercase"
                        : ""
                    }`}
                  >
                    {item.title}
                  </p>
                ) : null}
              </div>
            </NavLink>
          );
        })}
        <div
          className={`${
            location.pathname === "/settings/profile"
              ? isActiveStyle
              : isNotActiveStyle
          } mb-4`}
        >
          <div className={`flex justify-center items-center `}>
            <UserProfileStatus />
            {toggleSidebar ? (
              <p className="ml-2 lowercase first-letter:uppercase">
                {user?.name ?? "Meu perfil"}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
