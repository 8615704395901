/* eslint-disable no-restricted-syntax */
import React, { ChangeEvent } from "react";
import { IAcceptedFiles } from "../../../../shared/models/interfaces/acceptedfile.interface";
import CloseButton from "../../../../shared/components/Buttons/CloseButton.component";
import AudioRecorder from "./AudioRecorder.component";

const PrimaryOptions = ({
  handleFocus,
  handleSendClick,
  handleMessageChange,
  handleClose,
  // handleEmojiClick,
  openAttachsPicker,
  // openPicker,
  sending,
  setSending,
  setOpenAttachsPicker,
  message,
  thumbs,
  keyword,
  setKeyword,
  accept,
  inputRef,
  handleFileChange,
  // handleClick,
  handleFilePaste,
  sendAudio,
}: {
  handleSendClick: () => Promise<void>;
  handleFocus: (focus: boolean) => void;
  handleMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // handleEmojiClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  openAttachsPicker: boolean;
  // openPicker: boolean;
  setOpenAttachsPicker: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | undefined;
  sending: boolean;
  setSending: React.Dispatch<React.SetStateAction<boolean>>;
  thumbs: IAcceptedFiles;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  accept: string;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFilePaste: any;
  sendAudio: (blobUrl: string, blob: Blob) => Promise<void>;
  // handleClick: () => void;
}) => {
  const { AudioBar, handleMicClick, recording } = AudioRecorder({
    extraActions: sendAudio,
    setSending,
  });

  const getPlaceholder = () => {
    // if (sending) {
    //   return "Enviando...";
    // }
    return "Digite uma mensagem";
  };

  const getClassMessage = () => {
    // if (sending) {
    //   return "";
    // }
    if ((message && message.length > 0) || (thumbs && thumbs.length > 0)) {
      return "la-paper-plane";
    }
    return "la-microphone";
  };

  const getTitle = () => {
    // if (sending) {
    //   return "Enviando...";
    // }
    if ((message && message.length > 0) || (thumbs && thumbs.length > 0)) {
      return "Enviar mensagem";
    }
    return "Gravar áudio";
  };

  const items = [
    {
      onClick: () => setOpenAttachsPicker((prevState) => !prevState),
      icon: "la-ellipsis-v",
      title: "Mais opções",
    },
  ];

  const showItems = () => {
    const jsx = [];
    for (const item of items) {
      jsx.push(
        <button
          key={item.title}
          type="button"
          onClick={item.onClick}
          className={`las ${item.icon} text-[24px] w-[40px] h-[40px] border-0 text-gray-text hover:text-agitalks`}
          title={item.title}
          aria-label={item.title}
        />
      );
    }
    return jsx;
  };

  const handlePaste = (event: any) => {
    if (
      typeof event?.clipboardData?.files !== "undefined" &&
      event.clipboardData.files.length > 0
    ) {
      handleFilePaste(event.clipboardData.files);
    }
  };

  const getFullBar = () => {
    return (
      <div
        className={`${
          openAttachsPicker ? "" : "border-t-[1px]"
        } border-t-gray-ddd flex relative items-center`}
      >
        {openAttachsPicker ? (
          <CloseButton
            onClick={handleClose}
            extraClass="text-[24px] w-[40px] h-[40px] border-0 text-gray-text overflow-hidden transition-all"
          />
        ) : (
          showItems()
        )}
        <input
          className="hidden"
          ref={inputRef}
          type="file"
          multiple
          accept={accept}
          onChange={handleFileChange}
        />
        <textarea
          id="message-textarea"
          key="message-textarea"
          name="message-textarea"
          tabIndex={0}
          value={message}
          // disabled={sending}
          onKeyDown={async (event) => {
            handleFocus(true);
            if (sending) {
              return false;
            }
            if (event.key === "Enter" && !event.shiftKey && !event.ctrlKey) {
              event.preventDefault();
              if (keyword.length > 0) setKeyword("");
              if (
                (message && message.length > 0) ||
                (thumbs && thumbs.length > 0)
              )
                await handleSendClick();
            }
            return true;
          }}
          onBlur={() => handleFocus(false)}
          onPaste={handlePaste}
          onChange={handleMessageChange}
          className="bg-transparent flex-1 border-0 my-0 mx-[10px] p-[12px] h-[45px] resize-none text-[14px] outline-none"
          placeholder={getPlaceholder()}
        />
        <button
          type="button"
          // disabled={sending}
          onClick={async () => {
            if (sending) {
              return false;
            }
            if (keyword.length > 0) setKeyword("");
            if (
              (message && message.length > 0) ||
              (thumbs && thumbs.length > 0)
            ) {
              await handleSendClick();
            } else {
              await handleMicClick();
            }
            return true;
          }}
          className={`las ${getClassMessage()} text-[24px] w-[40px] h-[40px] border-0 text-gray-text`}
          title={getTitle()}
          aria-label={getTitle()}
        />
      </div>
    );
  };

  const getOnlyAudioBar = () => {
    return <AudioBar />;
  };

  return !recording || sending ? getFullBar() : getOnlyAudioBar();
};

export default PrimaryOptions;
